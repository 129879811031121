import React, { useState, useEffect } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  FormControlLabel,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useSnackbar } from "notistack";
import { baseUrl } from "../../config/config";
import {
  add_student_api,
  manage_class_timings_api,
  update_student_api,
} from "../../DAL/Students/Students";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { associates_list_api } from "../../DAL/Associates/Associates";
import { useContentSetting } from "../../Hooks/ContentSetting";
import { teachers_list_api } from "../../DAL/Teachers/Teachers";
import MUIAutocomplete from "../GeneralComponents/MUIAutocomplete";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
var moment = require("moment");

export default function ManageClassTime({
  onCloseDrawer,
  selectedObject,
  setStudentsList,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [classDays, setClassDays] = useState([
    {
      class_day: "Monday",
      class_time: "2018-01-01 05:30 am",
      class_duration: 30,
    },
  ]);

  const [state, setState] = useState({
    _id: "",
  });

  let CLASS_DAYS = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const get_filter = (selected) => {
    let class_days_array = [];
    CLASS_DAYS.map((day) => {
      let class_days = classDays.find(
        (class_day) => class_day.class_day === day
      );
      if (!class_days || selected == day) {
        class_days_array.push(day);
      }
    });
    return class_days_array;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let class_days_array = [];
    if (classDays.length > 0) {
      classDays.map((day) => {
        class_days_array.push({
          class_day: day.class_day,
          class_time: moment(day.class_time).format("hh:mm A"),
          class_duration: day.class_duration,
        });
      });
    }

    const formData = {
      class_days: class_days_array,
    };

    const result = await manage_class_timings_api(formData, state._id);
    if (result.code === 200) {
      setStudentsList((old) =>
        old.map((obj) => {
          if (obj._id === state._id) {
            return {
              ...obj,
              class_days: result.student.class_days,
            };
          }
          return obj;
        })
      );
      onCloseDrawer();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChangeClassDay = (event, index, type) => {
    let name = "class_time";
    let value = event.$d;
    if (!type || type !== "class_time") {
      name = event.target.name;
      value = event.target.value;
    }

    let temp_state = [...classDays];
    let temp_element = { ...temp_state[index] };
    temp_element[name] = value;
    temp_state[index] = temp_element;
    setClassDays(temp_state);
  };

  const handleAddClassDay = () => {
    setClassDays((old_array) => [
      ...old_array,
      { class_day: "", class_time: "2018-01-01 05:30 am", class_duration: 30 },
    ]);
  };

  const handleRemoveClassDay = (index) => {
    const updatedItems = [...classDays];
    updatedItems.splice(index, 1);
    setClassDays(updatedItems);
  };

  useEffect(() => {
    setState(selectedObject);
    let class_days_array = [];
    if (selectedObject.class_days) {
      selectedObject.class_days.map((class_day) => {
        class_days_array.push({
          class_day: class_day.class_day,
          class_time: `${class_day.class_time}`,
          // class_time: `2018-01-01 ${class_day.class_time}`,
          class_duration: class_day.class_duration,
        });
      });
    }
    setClassDays(class_days_array);
  }, [selectedObject]);

  return (
    <>
      <div className="container new-memories">
        <form onSubmit={handleSubmit}>
          <div className="row input-form">
            <div className="col-12">
              <div className="row">
                {classDays.length > 0 &&
                  classDays.map((class_day, index) => {
                    return (
                      <>
                        <div className="col-4">
                          <FormControl
                            variant="outlined"
                            className="mt-4 form-control"
                          >
                            <InputLabel id={`class_day${index}`}>
                              Class Day
                            </InputLabel>
                            <Select
                              labelId={`class_day${index}`}
                              id={`class_day${index}`}
                              label="Class Day"
                              name="class_day"
                              className="mui-select"
                              value={class_day.class_day}
                              onChange={(e) => {
                                handleChangeClassDay(e, index);
                              }}
                            >
                              {get_filter(class_day.class_day).map(
                                (day_name) => {
                                  return (
                                    <MenuItem value={day_name}>
                                      {day_name}
                                    </MenuItem>
                                  );
                                }
                              )}
                            </Select>
                          </FormControl>
                        </div>
                        <div className="col-4">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                              label="Time"
                              minutesStep={5}
                              value={class_day.class_time}
                              sx={{ color: "red" }}
                              className="mt-4 form-control mui-date-picker"
                              onChange={(e) => {
                                handleChangeClassDay(e, index, "class_time");
                              }}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                          </LocalizationProvider>
                        </div>
                        <div className="col-3">
                          <TextField
                            type="number"
                            className="mt-4 form-control"
                            id="class_duration"
                            label="Duration"
                            variant="outlined"
                            name="class_duration"
                            value={class_day.class_duration}
                            onChange={(e) => {
                              handleChangeClassDay(e, index);
                            }}
                          />
                        </div>
                        <div className="col-1 p-0 mt-4">
                          <Tooltip title="Remove">
                            <RemoveCircleOutlineIcon
                              onClick={() => handleRemoveClassDay(index)}
                              className="icon-remove-day"
                            />
                          </Tooltip>
                        </div>
                      </>
                    );
                  })}
                {classDays.length < 7 && (
                  <div className="col-12 mt-3">
                    <FormControlLabel
                      control={
                        <AddCircleOutlineIcon className="diary-icon-add mx-2" />
                      }
                      label="Add Class Day"
                      className="add-class-day"
                      onClick={handleAddClassDay}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="add--button text-end mt-3">
            <button disabled={isLoading}>
              {isLoading ? "Updating..." : "Update"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
