import { invokeApi } from "../../bl_libs/invokeApi";

export const teachers_list_api = async () => {
  const requestObj = {
    path: `api/teachers`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const add_teacher_api = async (data) => {
  const requestObj = {
    path: `api/teachers`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
      "Content-Type": "multipart/form-data",
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const change_teacher_password_api = async (teacher_id, data) => {
  const requestObj = {
    path: `api/teachers/change_password/${teacher_id}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const update_teacher_api = async (data, teacher_id) => {
  const requestObj = {
    path: `api/teachers/${teacher_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
      "Content-Type": "multipart/form-data",
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const delete_teacher_api = async (teacher_id) => {
  const requestObj = {
    path: `api/teachers/${teacher_id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const generate_teacher_token_api = async (teacher_id) => {
  const requestObj = {
    path: `api/teachers/generate_token/${teacher_id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
