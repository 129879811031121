import { invokeApi } from "../../bl_libs/invokeApi";

export const web_pagess_list_api = async (is_template) => {
  const requestObj = {
    path: `api/web_pages/list/${is_template}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const add_web_pages_api = async (data) => {
  const requestObj = {
    path: `api/web_pages`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
      "Content-Type": "multipart/form-data",
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const update_web_pages_api = async (data, page_id) => {
  const requestObj = {
    path: `api/web_pages/${page_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
      "Content-Type": "multipart/form-data",
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const update_page_content_api = async (data, page_id) => {
  const requestObj = {
    path: `api/web_pages/update_page_content/${page_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const delete_web_pages_api = async (page_id) => {
  const requestObj = {
    path: `api/web_pages/${page_id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const import_template_data_api = async (page_id) => {
  const requestObj = {
    path: `api/web_pages/import_template_data/${page_id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
