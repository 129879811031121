import { invokeApi } from "../../bl_libs/invokeApi";

export const associate_announcement_templates_api = async () => {
  const requestObj = {
    path: `api/general_routes/associate_announcement_templates`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const upload_general_image_api = async (data) => {
  const requestObj = {
    path: `api/general_routes/upload_image`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
